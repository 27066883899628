body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.list-item_image {
  height: 24px;
  vertical-align: middle;
  margin-right: 8px;
}

.sd-dropdown__input-field-component {
  height: 48px
}
#sv-nav-next {
  margin-left: auto;
}

.sb-btn{
  color: white;
}
